import BaseBanner from "./Base";
import { RED_200, RED_700 } from "../../../theme/colors";
import { OverdueInfoIcon } from "../../icons/OverdueInfoIcon";
import { StudentPaymentPlan } from "../Student.model";

export default function PaymentPlanPastDue({
    applicablePaymentPlan,
}: {
    applicablePaymentPlan: StudentPaymentPlan;
}) {
    return (
        <BaseBanner
            color={RED_700}
            backgroundColor={RED_200}
            message={`You are past due on your ${
                applicablePaymentPlan?.installmentsPaid + 1
            } planned payment. Please make a payment.`}
            Icon={OverdueInfoIcon}
        />
    );
}
