import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { GREY_500, RED_500 } from "../../theme/colors";
import { useState } from "react";
import MeadowInput from "../form/MeadowInput";
import { AdminService } from "../../services/AdminService";
import * as Sentry from "@sentry/react";

interface AddAccountModalProps {
    open: boolean;
    onClose: () => void;
}
export default function AddAccountModal({ open, onClose }: AddAccountModalProps) {
    const [schoolStudentId, setSchoolStudentId] = useState<string>("");
    const [schoolId, setSchoolId] = useState<string>("");
    const [error, setError] = useState<any>(null);
    const inputSx = {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
    };
    const navigateAndClose = async () => {
        try {
            const adminService = new AdminService();
            await adminService.addAdminAlly(schoolStudentId, schoolId);
            onClose();
            window.location.reload();
        } catch (error: any) {
            setError(error.response.data.error);
            Sentry.captureException(error);
        }
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "0.5rem",
                gap: "1rem",
                "& .MuiDialog-paper": {
                    maxWidth: "24.5rem",
                },
            }}
        >
            <DialogTitle
                sx={{
                    fontWeight: 500,
                    fontSize: "22px",
                    lineHeight: "2rem",
                    padding: "2rem 1.5rem 1rem 1.5rem",
                }}
            >
                Add an account
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    sx={{
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: GREY_500,
                        paddingBottom: "1rem",
                    }}
                >
                    {"Add a student ID and a school ID to become an ally on that student account."}
                </DialogContentText>
                {error && (
                    <DialogContentText
                        sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            lineHeight: "20px",
                            color: RED_500,
                            margin: "0.5rem 0",
                        }}
                    >
                        {error}
                    </DialogContentText>
                )}
                <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    <MeadowInput
                        sx={inputSx}
                        label={"Student ID"}
                        placeholder="Enter a student ID"
                        onChange={(e) => setSchoolStudentId(e.target.value)}
                        value={schoolStudentId}
                    />
                    <MeadowInput
                        sx={inputSx}
                        label={"School ID"}
                        placeholder="Enter a school ID"
                        onChange={(e) => setSchoolId(e.target.value)}
                        value={schoolId}
                        autoFocus={false}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    padding: "0 1.5rem 2rem 1.5rem",
                }}
            >
                <Button
                    disabled={!schoolStudentId || !schoolId}
                    onClick={navigateAndClose}
                    variant="primary"
                >
                    Add student account
                </Button>
                <Button sx={{ marginLeft: "0 !important" }} onClick={onClose} variant="secondary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
