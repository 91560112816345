// Define the shape of the PaymentsState

import { AccountAccessResponse, Student } from "../components/billing/Student.model";
import { GetPaymentPlanResponse } from "../services/PaymentPlanService";
import { PaymentInstrument } from "../services/Payments.model";
import { GetSchoolResponse } from "../services/School.model";

export enum PaymentInstrumentType {
    BANK_ACCOUNT = "BANK_ACCOUNT",
    PAYMENT_CARD = "PAYMENT_CARD",
}
export type PaymentMode =
    | "paymentDue"
    | "pastDuePlusNextPayment"
    | "pastDue"
    | "partial"
    | "paymentPlanFee"
    | "paymentPlanFeePlusFirstPayment"
    | "paymentPlanFeePlusPreviousBalance"
    | "";
export interface PaymentsStudent extends Student {
    paymentMode: PaymentMode | undefined;
    partialPaymentAmount: number;
    paymentMethodType: PaymentInstrumentType | null;
    accountAccessList: AccountAccessResponse[];
    amountToPay: number | undefined;
    /**
     * This is the total that was actually sent as a payment to Finix. It
     * includes the payment that will go to the school in addition to
     * any fees that were part of the payment.
     */
    totalPaid: number | undefined;
}

export interface PaymentsState {
    students: PaymentsStudent[];
    paymentInstrument: PaymentInstrument | null;
    currentStudentId: number | null;
    school: GetSchoolResponse | null;
    finixFraudToken: string | null;
    paymentPlanEnrollment: GetPaymentPlanResponse | null;
}
export type InstallmentStatus = "Upcoming" | "Past due" | "Due today" | "Paid";

export interface PaymentPlanInstallment {
    totalAmount: number;
    remainingAmount: number;
    dueDate: string;
    status: InstallmentStatus;
}

export const DEFAULT_PAYMENTS_STATE: PaymentsState = {
    students: [
        {
            paymentMode: "",
            partialPaymentAmount: 0,
            paymentMethodType: PaymentInstrumentType.BANK_ACCOUNT,
            accountNumber: "0",
            firstName: "",
            lastName: "",
            studentId: 0,
            status: "",
            statusLabel: "noPaymentDue",
            nextPaymentAmount: 0,
            nextPaymentDueDate: undefined,
            overduePaymentAmount: 0,
            overduePaymentDueDate: undefined,
            overduePlusNextPaymentAmount: 0,
            accountAccessList: [],
            censusDate: undefined,
            applicablePaymentPlan: undefined,
            applicablePeriodOfEnrollment: undefined,
            eligiblePaymentPlans: [],
            amountToPay: undefined,
            totalPaid: undefined,
        },
    ],
    school: null,
    finixFraudToken: null,
    paymentInstrument: null,
    currentStudentId: null,
    paymentPlanEnrollment: null,
};

// Define the ActionTypes for the reducer
export enum PaymentReducerActionTypes {
    INITIALIZE_STATE,
    SET_STUDENTS,
    SET_PAY_IN_FULL,
    SET_PAYMENT_MODE,
    SET_PARTIAL_PAYMENT_AMOUNT,
    SET_PAYMENT_METHOD_TYPE,
    RESET,
    SET_CURRENT_STUDENT_ID,
    SET_PAYMENT_INSTRUMENT,
    SET_STUDENT_SCHOOL,
    SET_FINIX_FRAUD_TOKEN,
    SET_PAYMENT_PLAN_ENROLLMENT,
    SET_AMOUNT_TO_PAY,
    SET_TOTAL_PAID,
}

// Define the shape of a PaymentReducerAction
export type PaymentReducerAction =
    | { type: PaymentReducerActionTypes.INITIALIZE_STATE; payload: PaymentsState }
    | { type: PaymentReducerActionTypes.SET_STUDENTS; payload: PaymentsStudent[] }
    | { type: PaymentReducerActionTypes.SET_PAY_IN_FULL; studentId: number; payload: boolean }
    | {
          type: PaymentReducerActionTypes.SET_PAYMENT_MODE;
          studentId: number;
          payload: PaymentMode;
      }
    | {
          type: PaymentReducerActionTypes.SET_PARTIAL_PAYMENT_AMOUNT;
          studentId: number;
          payload: number;
      }
    | {
          type: PaymentReducerActionTypes.SET_PAYMENT_METHOD_TYPE;
          studentId: number;
          payload: PaymentInstrumentType;
      }
    | { type: PaymentReducerActionTypes.RESET }
    | { type: PaymentReducerActionTypes.SET_CURRENT_STUDENT_ID; payload: number | null }
    | { type: PaymentReducerActionTypes.SET_PAYMENT_INSTRUMENT; payload: PaymentInstrument | null }
    | { type: PaymentReducerActionTypes.SET_STUDENT_SCHOOL; payload: GetSchoolResponse | null }
    | { type: PaymentReducerActionTypes.SET_FINIX_FRAUD_TOKEN; payload: string | null }
    | {
          type: PaymentReducerActionTypes.SET_PAYMENT_PLAN_ENROLLMENT;
          payload: GetPaymentPlanResponse | null;
      }
    | {
          type: PaymentReducerActionTypes.SET_AMOUNT_TO_PAY;
          payload: {
              studentId: number;
              amountToPay: number | undefined;
          };
      }
    | {
          type: PaymentReducerActionTypes.SET_TOTAL_PAID;
          payload: {
              studentId: number;
              totalPaid: number | undefined;
          };
      };

// Define the paymentsReducer function that handles state updates
export function paymentsReducer(state: PaymentsState, action: PaymentReducerAction): PaymentsState {
    switch (action.type) {
        case PaymentReducerActionTypes.INITIALIZE_STATE:
            return action.payload;
        case PaymentReducerActionTypes.SET_STUDENTS:
            return {
                ...state,
                students: [...action.payload],
            };
        case PaymentReducerActionTypes.SET_PAYMENT_MODE:
            return {
                ...state,
                students: [...state.students].map((student) => {
                    if (student.studentId === action.studentId) {
                        student.paymentMode = action.payload;
                    }
                    return student;
                }),
            };
        case PaymentReducerActionTypes.SET_PARTIAL_PAYMENT_AMOUNT:
            return {
                ...state,
                students: [...state.students].map((student) => {
                    if (student.studentId === action.studentId) {
                        student.partialPaymentAmount = action.payload;
                    }
                    return student;
                }),
            };
        case PaymentReducerActionTypes.SET_PAYMENT_METHOD_TYPE:
            return {
                ...state,
                students: [...state.students].map((student) => {
                    if (student.studentId === action.studentId) {
                        student.paymentMethodType = action.payload;
                    }
                    return student;
                }),
            };

        case PaymentReducerActionTypes.SET_CURRENT_STUDENT_ID:
            return {
                ...state,
                currentStudentId: action.payload,
            };
        case PaymentReducerActionTypes.SET_PAYMENT_INSTRUMENT:
            return {
                ...state,
                paymentInstrument: action.payload,
            };

        case PaymentReducerActionTypes.SET_STUDENT_SCHOOL:
            return {
                ...state,
                school: action.payload,
            };

        case PaymentReducerActionTypes.SET_FINIX_FRAUD_TOKEN:
            return {
                ...state,
                finixFraudToken: action.payload,
            };
        case PaymentReducerActionTypes.SET_PAYMENT_PLAN_ENROLLMENT:
            return {
                ...state,
                paymentPlanEnrollment: action.payload,
            };

        case PaymentReducerActionTypes.SET_AMOUNT_TO_PAY:
            return {
                ...state,
                students: [...state.students].map((student) => {
                    if (student.studentId === action.payload.studentId) {
                        student.amountToPay = action.payload.amountToPay;
                    }
                    return student;
                }),
            };
        case PaymentReducerActionTypes.SET_TOTAL_PAID:
            return {
                ...state,
                students: [...state.students].map((student) => {
                    if (student.studentId === action.payload.studentId) {
                        student.totalPaid = action.payload.totalPaid;
                    }
                    return student;
                }),
            };

        case PaymentReducerActionTypes.RESET:
            return {
                students: [],
                paymentInstrument: null,
                currentStudentId: null,
                school: null,
                finixFraudToken: null,
                paymentPlanEnrollment: null,
            };
        default:
            return state;
    }
}
