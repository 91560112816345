import * as Sentry from "@sentry/react";
import MeadowContainer from "../../container";
import {
    ACCOUNT_HOLDER_NAME_EMPTY,
    ACCOUNT_NUMBER_EMPTY,
    ACCOUNT_TYPE_EMPTY,
    finixBankAccountKeyArray,
    ROUTING_NUMBER_EMPTY,
} from "../../../constants";
import { GREY_100, GREY_800, RED_500, WHITE_000 } from "../../../theme/colors";
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormEvent, MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import {
    errorFieldIframeSx,
    errorSelectSx,
    errorSx,
    fieldHolderSx,
    fieldIframeSx,
    inputStyle,
    selectErrorSx,
    selectSx,
} from "./styles";
import CancelDialog from "../CancelDialog";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { PaymentService } from "../../../services/PaymentService";
import SecurePayment from "../SecurePayment";
import errorInfo from "../../../assets/error_info.svg";
import { ErrorTypes } from "../../../enums/errors";
import { PaymentContext } from "../../../providers/PaymentsProvider";
import LoadingDialog from "../../modal/LoadingDialog";
import useAmountToPay from "../../../hooks/useAmountToPay";

interface FieldType {
    id: string;
    finixType: "name" | "account_number" | "bank_code";
    placeholder: string;
    ref: MutableRefObject<HTMLElement | null>;
    validation: string;
    autoComplete: string;
}
export default function BankAccount() {
    const navigate = useNavigate();
    const { setPaymentInstrument, currentStudentId } = useContext(PaymentContext);
    const { isMobile } = useBreakpoint();
    const formRef = useRef<HTMLFormElement | null>(null);
    const nameRef = useRef<HTMLElement | null>(null);
    const accountNumberRef = useRef<HTMLElement | null>(null);
    const bankCodeRef = useRef<HTMLElement | null>(null);
    const [isFinixLoaded, setIsFinixLoaded] = useState<boolean>(false);
    const [accountType, setAccountType] = useState<string[]>([]);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [finixErrorState, setFinixErrorState] = useState<any>(null);
    const [errorFields, setErrorFields] = useState<string[]>([]);
    const [finixForm, setFinixForm] = useState<any>(() =>
        window.PaymentForm.bankAccount((state: any) => {
            setFinixErrorState(state);
        })
    );
    const fieldsListRef = useRef<FieldType[]>([
        {
            id: "name",
            finixType: "name",
            placeholder: "e.g. John Doe",
            validation: "required",
            ref: nameRef,
            autoComplete: "name",
        },
        {
            id: "account_number",
            finixType: "account_number",
            placeholder: "Enter account number",
            validation: "required",
            autoComplete: "account",
            ref: accountNumberRef,
        },
        {
            id: "bank_code",
            finixType: "bank_code",
            placeholder: "9 digits",
            validation: "required",
            autoComplete: "bank_code",
            ref: bankCodeRef,
        },
    ]);
    useAmountToPay("bank-account");

    useEffect(() => {
        // this buys us a little time to load the finix form, and make sure the refs are set
        // https://muffinresearch.co.uk/does-settimeout-solve-the-domcontentloaded-problem/
        const timeoutId = setTimeout(() => {
            if (!nameRef.current || !accountNumberRef.current || !bankCodeRef.current) {
                return;
            }
            if (!finixForm) {
                setFinixForm(
                    window.PaymentForm.bankAccount((state: any) => {
                        setFinixErrorState(state);
                    })
                );
            }
            fieldsListRef.current.forEach((field) => {
                const formInputIframe = finixForm.field(field.finixType, {
                    validations: field.validation,
                    autoComplete: field.autoComplete,
                    placeholder: {
                        text: field.placeholder,
                        hideOnFocus: false,
                    },
                    styles: {
                        default: inputStyle,
                        // This doesn't work like it should :(
                        // error: {
                        //     border: `1px solid ${RED_500}`,
                        // },
                    },
                });
                field?.ref?.current?.appendChild(formInputIframe);
            });
            setIsFinixLoaded(true);
        }, 0);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const innerContainerSx = {
        width: isMobile ? "100%" : "24.5rem",
    };
    const errorLabelWrapperSX = {
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        paddingBottom: "1rem",
    };
    const errorLabelSX = {
        color: RED_500,
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: "1.25rem",
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const errorList: string[] = [];

        // finix doesnt handle validation for account type
        if (accountType.length === 0) {
            // the user didn't select a account type
            errorList.push("account_type");
        }
        finixBankAccountKeyArray.forEach((item: string) => {
            if (finixErrorState && finixErrorState[item].errorMessages.length > 0) {
                errorList.push(item);
                setErrorFields(errorList);
            }
        });

        // if there are errors, don't submit
        if (errorList.length > 0) {
            setIsProcessing(false);
            setErrorFields(errorList);
            return;
        }

        try {
            setIsProcessing(true);
            finixForm.submitWithData(
                import.meta.env.VITE_FINIX_ENVIRONMENT,
                import.meta.env.VITE_FINIX_APP_ID,
                { account_type: accountType[0].toUpperCase() },
                async (err: any, res: any) => {
                    try {
                        /**
                         * When we get a Finix error on this form, the response
                         * has the error inside of the data object. We need to
                         * check for that and throw the error if it exists. This
                         * will send the specific errors to sentry so we can start
                         * to understand why students see 422s here.
                         */
                        if (err && res?.data?._embedded?.errors) {
                            throw res?.data?._embedded?.errors;
                        }

                        if (err) {
                            throw err;
                        }

                        const token = res?.data?.id;
                        const paymentService = new PaymentService();
                        const paymentResponse = await paymentService.makePaymentMethod(token);

                        setPaymentInstrument(paymentResponse);
                        setIsProcessing(false);
                        navigate("/payment/summary");
                    } catch (error: any) {
                        // something with our api went wrong
                        // for now we will just show a generic error
                        setIsProcessing(false);
                        setErrorMessage(ErrorTypes.BANK_ACCOUNT_ERROR);
                        /**
                         * If this error comes from Finix, there
                         * could be multiple errors. We need to
                         * capture each one.
                         */
                        if (Array.isArray(error)) {
                            Sentry.addBreadcrumb({
                                message: "Error creating payment instrument",
                                data: {
                                    finixErrors: error,
                                    studentId: currentStudentId,
                                },
                            });
                            Sentry.captureException(new Error("Error creating payment instrument"));
                        } else {
                            Sentry.captureException(error);
                        }
                    }
                }
            );
        } catch (error: unknown) {
            // something with finix went wrong
            setIsProcessing(false);
            setErrorMessage(ErrorTypes.GENERIC);
            Sentry.captureException(error);
        }
    };
    const handleChange = (event: SelectChangeEvent<typeof accountType>) => {
        const {
            target: { value },
        } = event;
        setAccountType(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
    };
    return (
        <MeadowContainer
            fullWidth
            showSimpleHeader={true}
            noPadding
            simpleHeaderText="Enter bank information"
            sx={{
                backgroundColor: GREY_800,
                alignItems: isMobile ? "unset" : "center",
                borderBottom: `1px solid ${GREY_100}`,
            }}
        >
            <Box sx={innerContainerSx}>
                <Box
                    sx={{
                        backgroundColor: WHITE_000,
                        padding: "1.25rem 1rem",
                        display: isFinixLoaded ? "flex" : "none",
                        width: isMobile ? "unset" : "24.5rem",
                        paddingBottom: "0",
                        borderWidth: "1px 0px",
                        borderStyle: "solid",
                        borderColor: GREY_100,
                    }}
                >
                    <FormControl sx={{ width: isMobile ? "100%" : "24.5rem" }}>
                        {errorMessage && errorMessage.length > 0 && (
                            <Box sx={errorLabelWrapperSX}>
                                <img src={errorInfo} />
                                <Typography sx={errorLabelSX}>{errorMessage}</Typography>
                            </Box>
                        )}
                        <form ref={formRef} onSubmit={handleSubmit}>
                            <Box sx={fieldHolderSx} className="field-holder">
                                <label>Account holder name:</label>
                                <Box
                                    id="name"
                                    className="field"
                                    sx={
                                        errorFields.includes("name")
                                            ? errorFieldIframeSx
                                            : fieldIframeSx
                                    }
                                    ref={nameRef}
                                ></Box>
                            </Box>
                            {errorFields.includes("name") && (
                                <Box sx={errorSx}>{ACCOUNT_HOLDER_NAME_EMPTY}</Box>
                            )}

                            <Box sx={fieldHolderSx} className="field-holder">
                                <label>Routing number:</label>
                                <Box
                                    id="bank_code"
                                    sx={
                                        errorFields.includes("bank_code")
                                            ? errorFieldIframeSx
                                            : fieldIframeSx
                                    }
                                    className="field"
                                    ref={bankCodeRef}
                                ></Box>
                            </Box>
                            {errorFields.includes("bank_code") && (
                                <Box sx={errorSx}>{ROUTING_NUMBER_EMPTY}</Box>
                            )}
                            <Box sx={fieldHolderSx} className="field-holder">
                                <label>Account type:</label>
                                <Select
                                    displayEmpty
                                    sx={
                                        errorFields.includes("account_type")
                                            ? errorSelectSx
                                            : selectSx
                                    }
                                    placeholder="Select"
                                    id="account-type"
                                    value={accountType}
                                    onChange={handleChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <Box sx={{ color: "#818181" }}>Select</Box>;
                                        }
                                        return selected.join(", ");
                                    }}
                                >
                                    <MenuItem sx={{ display: "none" }} disabled value=""></MenuItem>
                                    <MenuItem value={"Checking"}>Checking</MenuItem>
                                    <MenuItem value={"Savings"}>Savings</MenuItem>
                                </Select>
                            </Box>
                            {errorFields.includes("account_type") && (
                                <Box sx={selectErrorSx}>{ACCOUNT_TYPE_EMPTY}</Box>
                            )}
                            <Box sx={fieldHolderSx} className="field-holder">
                                <label>Account Number:</label>
                                <Box
                                    id="account_number"
                                    sx={
                                        errorFields.includes("account_number")
                                            ? errorFieldIframeSx
                                            : fieldIframeSx
                                    }
                                    className="field"
                                    ref={accountNumberRef}
                                ></Box>
                            </Box>
                            {errorFields.includes("account_number") && (
                                <Box sx={errorSx}>{ACCOUNT_NUMBER_EMPTY}</Box>
                            )}
                        </form>
                    </FormControl>
                </Box>

                <Box sx={{ padding: "0 1.5rem", width: isMobile ? "unset" : "24.5rem" }}>
                    <Button
                        sx={{
                            marginTop: "1.5rem",
                        }}
                        disabled={isProcessing}
                        onClick={(e: any) => handleSubmit(e)}
                        variant="primary"
                        type="submit"
                    >
                        Continue
                    </Button>
                    <Button
                        sx={{
                            marginTop: "0.75rem",
                        }}
                        onClick={() => setIsCancel(true)}
                        variant="secondary"
                    >
                        Cancel
                    </Button>
                    <SecurePayment />
                </Box>
            </Box>
            <LoadingDialog open={isProcessing} />
            <>{isCancel && <CancelDialog open={isCancel} onClose={() => setIsCancel(false)} />}</>
        </MeadowContainer>
    );
}
