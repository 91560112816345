import { ListItem, Card, Typography, Box } from "@mui/material";
import { GREY_100 } from "../../../theme/colors";
import { BillDueBanner } from "../BillDueBanner";
import { useContext, useEffect, useState } from "react";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { PaymentsStudent } from "../../../reducers/PaymentsReducer";
import { Student } from "../Student.model";
import { PaymentContext } from "../../../providers/PaymentsProvider";
import { useNavigate } from "react-router-dom";
import { STUDENT_STATUS } from "../../../constants";
import InnerDesktop from "./InnerDesktop";
import InnerMobile from "./InnerMobile";
import { adminAccountRowWrapperSx, adminRemoveAllyButtonSx, nameSx, removeBoxSx } from "./styles";
import { Logging } from "../../../services/Logging";
import { UserContext } from "../../../context/UserContext";
import { MeadowButton } from "../../form/MeadowButton";
import trash from "../../../assets/trash.svg";
import { AuthService } from "../../../services/AuthService";
import ConfirmRemoveAccountModal from "./ConfirmRemoveAccountModal.js";

interface StudentCardProps {
    student: PaymentsStudent;
    makeAPayment?: boolean;
}

export default function StudentCard({ student, makeAPayment = false }: StudentCardProps) {
    const { isMobile } = useBreakpoint();
    const { user } = useContext(UserContext);
    const navigate = useNavigate();
    const { setCurrentStudentId } = useContext(PaymentContext);
    const [isRemoveAccountModalOpen, setIsRemoveAccountModalOpen] = useState(false);
    const cardSx = {
        padding: "1rem",
        width: isMobile ? "100%" : "50.5rem",
        boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
        borderRadius: "0.5rem",
    };

    const handleViewBill = async (student: Student) => {
        setCurrentStudentId(student.studentId);
        navigate("/billing/details");
    };
    const handleMakeApayment = (student: Student) => {
        const utmString = makeAPayment
            ? "utm_source=billing-details"
            : "utm_source=billing-overview";

        setCurrentStudentId(student.studentId);
        navigate(`/payment/amount?${utmString}`);
    };

    const shouldShowNextPaymentHelper =
        student.status === STUDENT_STATUS.PAST_DUE &&
        !!student.nextPaymentDueDate &&
        student.nextPaymentAmount > 0 &&
        !makeAPayment;

    useEffect(() => {
        Logging.trackStudentAccountImpression(student);
    }, []);

    return (
        <>
            <ListItem data-testid="student-card" sx={{ padding: 0 }}>
                <Card sx={cardSx}>
                    <Box>
                        <Box sx={adminAccountRowWrapperSx}>
                            <Typography sx={nameSx}>
                                {student.firstName} {student.lastName}
                            </Typography>
                            {user?.isAdmin && (
                                <MeadowButton
                                    sx={adminRemoveAllyButtonSx}
                                    onClick={() => {
                                        setIsRemoveAccountModalOpen(true);
                                    }}
                                >
                                    <Box data-testid="remove-ally-button" sx={removeBoxSx}>
                                        <img src={trash} alt="delete" />
                                        <Typography>Remove</Typography>
                                    </Box>
                                </MeadowButton>
                            )}
                        </Box>

                        <Typography
                            sx={{
                                ...nameSx,
                                fontWeight: 400,
                                marginBottom: "1rem",
                                paddingBottom: "1rem",
                                borderBottom: `1px solid ${GREY_100}`,
                            }}
                        >
                            Student account:{" "}
                            <span style={{ fontWeight: 500 }}>{student.accountNumber}</span>
                        </Typography>
                    </Box>

                    {student.status === STUDENT_STATUS.BILLING_DISABLED && (
                        <BillDueBanner student={student} />
                    )}
                    {student.status !== STUDENT_STATUS.BILLING_DISABLED &&
                        (isMobile ? (
                            <InnerMobile
                                {...{
                                    student,
                                    handleMakeApayment,
                                    makeAPayment,
                                    shouldShowNextPaymentHelper,
                                    handleViewBill,
                                }}
                            />
                        ) : (
                            <InnerDesktop
                                {...{
                                    student,
                                    handleMakeApayment,
                                    makeAPayment,
                                    handleViewBill,
                                    shouldShowNextPaymentHelper,
                                }}
                            />
                        ))}
                </Card>
            </ListItem>
            {isRemoveAccountModalOpen && (
                <ConfirmRemoveAccountModal
                    onClose={() => setIsRemoveAccountModalOpen(false)}
                    studentId={student.studentId}
                />
            )}
        </>
    );
}
