import { AuthSession } from "../../models/AuthSession.model";
import { PaymentsStudent } from "../../reducers/PaymentsReducer";
import { BillingCharge, FormattedChargesInterface } from "./Billing.model";
import { toWordsOrdinal } from "number-to-words";
import AccountAccessCard from "./account-access/AccountAccessCard";
import PaymentPlanDueBanner from "./status-banner/PaymentPlanDueBanner";
import { STUDENT_STATUS } from "../../constants";
import BillOverdueBanner from "./status-banner/BillOverdueBanner";
import PendingPaymentBanner from "./status-banner/PendingPaymentBanner.js";
import BillPendingBanner from "./status-banner/BillPendingBanner.js";
import PaymentPlanPastDue from "./status-banner/PaymentPlanPastDue.js";

export const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const formatterNoDollarSign = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
});

const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const formatChargeDate = (
    chargeDate: string,
    { dateOnly = false, useShortMonthFormat = false }
) => {
    if (chargeDate === "") {
        return "-"; // TODO: decide a default text for unknown charge dates
    }

    const actual = new Date(dateOnly ? chargeDate.substring(0, 10) : chargeDate);
    const chargeMonth = monthNames[actual.getUTCMonth()];
    const chargeDay = actual.getUTCDate();
    const chargeYear = actual.getUTCFullYear();

    return `${
        useShortMonthFormat ? chargeMonth.substring(0, 3) : chargeMonth
    } ${chargeDay}, ${chargeYear}`;
};

export const calculateDueDate = (dueDate: string) => {
    const actual = new Date(dueDate);
    const dueMonth = monthNames[actual.getMonth()];
    const displayForMonth = dueMonth.slice(0, 3);
    const dueDay = actual.getDate();

    return displayForMonth + " " + dueDay;
};

export const calculateDaysLeft = (dueDate: string) => {
    const today = new Date();
    const actual = new Date(dueDate);
    const diffInTime = actual.getTime() - today.getTime();
    const numberOfDays = Math.round(diffInTime / (1000 * 3600 * 24));

    return numberOfDays;
};

export const formatBasisPoints = (basisPoints: number) => {
    const decimalForm = basisPoints / 100;

    return `${decimalForm.toFixed(2)}%`;
};
export const formatCharges = (charges: BillingCharge[]) => {
    const chargesFormatted: FormattedChargesInterface[] = [];
    charges.map((charge) => {
        const { date } = charge;
        // check if date is already in the array
        const index = chargesFormatted.findIndex((item: any) => item.date === date);
        if (index === -1) {
            // if date is not found, add it to the array
            chargesFormatted.push({ date, charges: [charge] });
        } else {
            // if date is found, add the charge to the array
            chargesFormatted[index].charges.push(charge);
        }
    });
    return chargesFormatted;
};

export const verifyAccessControls = (user: AuthSession, student: PaymentsStudent) => {
    const studentRole = user.students.find((item) => item.id === student.studentId);

    if (studentRole && (studentRole.role === "student" || studentRole.role === "admin")) {
        return <AccountAccessCard student={student} />;
    } else {
        return <></>;
    }
};

export const capitalizeFirstLetter = (string: string) => {
    if (string.length === 0) {
        return string;
    }
    const lowerCased = string.toLowerCase();
    const capitalized = lowerCased.charAt(0).toUpperCase() + lowerCased.substring(1);

    return capitalized;
};

export const determineOrdinal = (number: number) => {
    return capitalizeFirstLetter(toWordsOrdinal(number));
};

export const determineBanner = (
    student: PaymentsStudent,
    shouldShowDueDates: boolean
): JSX.Element | null => {
    // if the student has a billing disabled status
    if (student.status === STUDENT_STATUS.BILLING_DISABLED) {
        return <BillPendingBanner />;
    }
    // if the student has a payment due today and has an applicable payment plan
    if (student.status === STUDENT_STATUS.PAYMENT_DUE_TODAY && student.applicablePaymentPlan) {
        return <PaymentPlanDueBanner applicablePaymentPlan={student.applicablePaymentPlan} />;
    }
    // if the student has a past due status and has an applicable payment plan
    if (student.status === STUDENT_STATUS.PAST_DUE && student.applicablePaymentPlan) {
        return <PaymentPlanPastDue applicablePaymentPlan={student.applicablePaymentPlan} />;
    }
    // if the student has a past due balance, and the config is set to show due dates, and the student does not have an applicable payment plan
    if (
        student.status === STUDENT_STATUS.PAST_DUE &&
        shouldShowDueDates &&
        !student.applicablePaymentPlan
    ) {
        return <BillOverdueBanner />;
    }
    // if the student has a pending payment status
    if (student.status === STUDENT_STATUS.PENDING) {
        return <PendingPaymentBanner />;
    }

    // if the student has a payment due status, no applicable payment plan, and the census date is in the future
    if (
        student.status === STUDENT_STATUS.PAYMENT_DUE &&
        !student.applicablePaymentPlan &&
        student.censusDate &&
        new Date().toISOString().substring(0, 10) <= student.censusDate
    ) {
        return <BillPendingBanner />;
    }

    return null;
};
