import React, { useState } from "react";
import { useEffect, useReducer } from "react";
import {
    PaymentReducerActionTypes,
    PaymentsState,
    paymentsReducer,
    PaymentsStudent,
    PaymentInstrumentType,
    DEFAULT_PAYMENTS_STATE,
    PaymentMode,
} from "../reducers/PaymentsReducer";
import { PaymentInstrument } from "../services/Payments.model";
import { GetSchoolResponse } from "../services/School.model";
import { GetPaymentPlanResponse } from "../services/PaymentPlanService";

interface PaymentProviderProps {
    children: React.ReactNode;
}

export interface PaymentContextValue extends PaymentsState {
    setStudents: (students: PaymentsStudent[]) => void;
    setPaymentMode: (studentId: number, paymentMode: PaymentMode) => void;
    setPartialPaymentAmount: (studentId: number, partialPaymentAmount: number) => void;
    setPaymentMethodType: (studentId: number, paymentMethodType: PaymentInstrumentType) => void;
    reset: () => void;
    setCurrentStudentId: (studentId: number) => void;
    getCurrentStudent: () => PaymentsStudent | undefined;
    setPaymentInstrument: (paymentInstrument: PaymentInstrument) => void;
    getPaymentInstrument: () => PaymentInstrument | null;
    setAmountToPay: (amountToPay: number | undefined, studentId: number) => void;
    getAmountToPay: () => number | undefined;
    setTotalPaid: (totalPaid: number | undefined, studentId: number) => void;
    getTotalPaid: () => number | undefined;
    setStudentSchool: (school: GetSchoolResponse) => void;
    getStudentSchool: () => GetSchoolResponse | null;
    setFinixFraudToken: (finixFraudToken: string) => void;
    getFinixFraudToken: () => string | null;
    setPaymentPlanEnrollment: (paymentPlanEnrollment: GetPaymentPlanResponse) => void;
    getPaymentPlanEnrollment: () => GetPaymentPlanResponse | null;
}

export const PaymentContext = React.createContext<PaymentContextValue>({
    students: [],
    setStudents: () => {},
    setPaymentMode: () => {},
    setPartialPaymentAmount: () => {},
    setPaymentMethodType: () => {},
    reset: () => {},
    currentStudentId: null,
    paymentInstrument: null,
    setCurrentStudentId: () => {},
    getCurrentStudent: () => undefined,
    setPaymentInstrument: () => {},
    getPaymentInstrument: () => null,
    setAmountToPay: () => {},
    getAmountToPay: () => undefined,
    setTotalPaid: () => {},
    getTotalPaid: () => undefined,
    school: null,
    setStudentSchool: () => {},
    getStudentSchool: () => null,
    finixFraudToken: null,
    setFinixFraudToken: () => {},
    getFinixFraudToken: () => null,
    paymentPlanEnrollment: null,
    setPaymentPlanEnrollment: () => {},
    getPaymentPlanEnrollment: () => null,
});

export function PaymentProvider({ children }: PaymentProviderProps) {
    const [paymentsState, dispatch] = useReducer(paymentsReducer, {
        students: [],
        currentStudentId: null,
        paymentInstrument: null,
        school: null,
        finixFraudToken: null,
        paymentPlanEnrollment: null,
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        async function loadDefaultState() {
            dispatch({ type: PaymentReducerActionTypes.RESET });
            dispatch({
                type: PaymentReducerActionTypes.INITIALIZE_STATE,
                payload: DEFAULT_PAYMENTS_STATE,
            });
        }
        loadDefaultState().finally(() => {
            setIsLoading(false);
        });
    }, []);

    function getCurrentStudent(): PaymentsStudent | undefined {
        return paymentsState.students.find(
            (student) => student.studentId === paymentsState.currentStudentId
        );
    }

    function setStudentSchool(school: GetSchoolResponse) {
        dispatch({
            type: PaymentReducerActionTypes.SET_STUDENT_SCHOOL,
            payload: school,
        });
    }

    function getStudentSchool(): GetSchoolResponse | null {
        return paymentsState.school;
    }

    function setFinixFraudToken(token: string) {
        dispatch({
            type: PaymentReducerActionTypes.SET_FINIX_FRAUD_TOKEN,
            payload: token,
        });
    }

    function getFinixFraudToken(): string | null {
        return paymentsState.finixFraudToken;
    }

    function setAmountToPay(amountToPay: number | undefined, studentId: number) {
        dispatch({
            type: PaymentReducerActionTypes.SET_AMOUNT_TO_PAY,
            payload: {
                studentId,
                amountToPay,
            },
        });
    }

    function getAmountToPay(): number | undefined {
        return paymentsState.students.find(
            (student) => student.studentId === paymentsState.currentStudentId
        )?.amountToPay;
    }

    function setTotalPaid(totalPaid: number | undefined, studentId: number) {
        dispatch({
            type: PaymentReducerActionTypes.SET_TOTAL_PAID,
            payload: {
                studentId,
                totalPaid,
            },
        });
    }

    function getTotalPaid(): number | undefined {
        return paymentsState.students.find(
            (student) => student.studentId === paymentsState.currentStudentId
        )?.totalPaid;
    }

    function getPaymentInstrument(): PaymentInstrument | null {
        return paymentsState.paymentInstrument;
    }

    function setStudents(students: PaymentsStudent[]) {
        dispatch({
            type: PaymentReducerActionTypes.SET_STUDENTS,
            payload: students,
        });
    }

    function setPaymentMode(studentId: number, paymentMode: PaymentMode) {
        dispatch({
            type: PaymentReducerActionTypes.SET_PAYMENT_MODE,
            studentId,
            payload: paymentMode,
        });
    }

    function setPartialPaymentAmount(studentId: number, partialPaymentAmount: number) {
        dispatch({
            type: PaymentReducerActionTypes.SET_PARTIAL_PAYMENT_AMOUNT,
            studentId,
            payload: partialPaymentAmount,
        });
    }

    function setPaymentMethodType(studentId: number, paymentMethod: PaymentInstrumentType) {
        dispatch({
            type: PaymentReducerActionTypes.SET_PAYMENT_METHOD_TYPE,
            studentId,
            payload: paymentMethod,
        });
    }

    function setCurrentStudentId(studentId: number) {
        dispatch({
            type: PaymentReducerActionTypes.SET_CURRENT_STUDENT_ID,
            payload: studentId,
        });
    }

    function setPaymentInstrument(paymentInstrument: PaymentInstrument) {
        dispatch({
            type: PaymentReducerActionTypes.SET_PAYMENT_INSTRUMENT,
            payload: paymentInstrument,
        });
    }

    function reset() {
        dispatch({ type: PaymentReducerActionTypes.RESET });
    }

    function setPaymentPlanEnrollment(paymentPlanEnrollment: GetPaymentPlanResponse) {
        dispatch({
            type: PaymentReducerActionTypes.SET_PAYMENT_PLAN_ENROLLMENT,
            payload: paymentPlanEnrollment,
        });
    }

    function getPaymentPlanEnrollment(): GetPaymentPlanResponse | null {
        return paymentsState.paymentPlanEnrollment;
    }

    return (
        <PaymentContext.Provider
            value={{
                ...paymentsState,
                setStudents,
                setPaymentMode,
                setPartialPaymentAmount,
                setPaymentMethodType,
                reset,
                setCurrentStudentId,
                getCurrentStudent,
                setPaymentInstrument,
                getPaymentInstrument,
                setAmountToPay,
                getAmountToPay,
                setTotalPaid,
                getTotalPaid,
                setStudentSchool,
                getStudentSchool,
                setFinixFraudToken,
                getFinixFraudToken,
                setPaymentPlanEnrollment,
                getPaymentPlanEnrollment,
            }}
        >
            {!isLoading && children}
        </PaymentContext.Provider>
    );
}

export function withPaymentContext(Component: React.ComponentType<PaymentContextValue>) {
    return function PaymentContextWrapper(props: any) {
        return (
            <PaymentContext.Consumer>
                {(context) => <Component {...props} {...context} />}
            </PaymentContext.Consumer>
        );
    };
}
