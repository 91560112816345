import { Button, Typography } from "@mui/material";
import addIcon from "../../assets/add_icon.svg";
import { BLUE_500 } from "../../theme/colors";
import { useState } from "react";
import AddAccountModal from "./AddAccountModal";

export const AddAccountButton = () => {
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    return (
        <>
            <Button
                data-testid="add-account-button"
                onClick={() => setIsDialogOpen(true)}
                sx={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                    margin: "1rem 0",
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
            >
                <img src={addIcon} />
                <Typography sx={{ color: BLUE_500 }}>Add account</Typography>
            </Button>

            {isDialogOpen && (
                <AddAccountModal open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
            )}
        </>
    );
};
