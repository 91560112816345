import { useContext, useEffect, useState } from "react";
import MeadowContainer from "../../container";
import useBreakpoint from "../../../hooks/useBreakpoint";
import LoadingDialog from "../../modal/LoadingDialog";
import { BLUE_700, GREY_500 } from "../../../theme/colors";
import { Box, Button, Card, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { determineOrdinal, formatChargeDate, formatter } from "../utilities";
import PoweredByMeadow from "../../footer/PoweredByMeadow";
import { MeadowTooltip } from "../../icons/MeadowTooltip";
import { PaymentPlanInstallment } from "../Student.model";
import {
    GetPaymentPlanResponse,
    PaymentPlanService,
    Tila,
} from "../../../services/PaymentPlanService";
import * as Sentry from "@sentry/react";
import CheckboxRow from "../../form/CheckboxRow";
import { PaymentContext } from "../../../providers/PaymentsProvider";
import {
    buttonContainerSx,
    buttonSectionSx,
    buttonSx,
    checkboxWrapper,
    disclaimerSectionSx,
    disclaimerSx,
    dueDateTextSx,
    innerContainerSx,
    meadowContainerSx,
    paymentScheduleCardInnerSx,
    paymentScheduleCardSx,
    paymentScheduleHeaderRowSx,
    paymentScheduleRow,
    paymentScheduleRowWrapperSx,
    planInformationInnerSx,
    planInformationSx,
    rowTextSx,
    rowWithtooltipSx,
    rowWithTooltipWrapperSx,
    simpleHeaderSx,
    tilaCardInnerSx,
    tilaCardSx,
    tilaNameSx,
    tilaRowSx,
    tilaRowTextBoldSx,
    tilaRowTextSx,
    totalSx,
} from "./styles";
import { ConfigContext } from "../../../context/ConfigContext";
import { paymentPlanFeeTooltipText } from "../../../constants";

export default function PlanDetails() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [paymentPlan, setPaymentPlan] = useState<GetPaymentPlanResponse>();
    const [termsCheck, setTermsCheck] = useState<boolean>(false);
    const [isCheckedError, setIsCheckedError] = useState<boolean>(false);
    const [paymentPlanService] = useState(() => new PaymentPlanService());
    const { setPaymentPlanEnrollment, reset } = useContext(PaymentContext);

    const navigate = useNavigate();
    const { isMobile } = useBreakpoint();
    const { state } = useLocation();
    const { config } = useContext(ConfigContext);

    useEffect(() => {
        if (!state || !state.paymentPlanId || !state.paymentPlanId.paymentPlanId) {
            navigate("/billing");
            return;
        }
        async function fetchPaymentPlan() {
            try {
                setIsLoading(true);

                const paymentPlan = await paymentPlanService.getPaymentPlan(
                    state.paymentPlanId.paymentPlanId
                );

                setPaymentPlan(paymentPlan);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
                Sentry.captureException(error);
                navigate("/billing");
            }
        }
        fetchPaymentPlan();
    }, []);

    const labelStringAutopayOn = () => (
        <>
            I agree to{" "}
            <Link style={{ textDecoration: "none", color: BLUE_700 }} to="/payment-plan/legal">
                autopay
            </Link>{" "}
            and to the{" "}
            <Link style={{ textDecoration: "none", color: BLUE_700 }} to="/payment-plan/legal">
                terms & conditions
            </Link>{" "}
        </>
    );

    const labelStringAutopayOff = () => (
        <>
            I agree to the plan{" "}
            <Link style={{ textDecoration: "none", color: BLUE_700 }} to="/payment-plan/legal">
                terms & conditions
            </Link>{" "}
        </>
    );

    const handleCancel = () => {
        navigate(-1);
        reset();
    };

    const handleContinue = () => {
        if (!paymentPlan) {
            navigate("/billing");
            return;
        }
        if (!termsCheck) {
            setIsCheckedError(true);
            return;
        } else {
            setIsCheckedError(false);
            setPaymentPlanEnrollment(paymentPlan);
            navigate("/payment/amount");
        }
    };

    return (
        <MeadowContainer
            fullWidth
            showSimpleHeader={!isLoading}
            simpleHeaderText="Plan details"
            lightSimpleHeader={true}
            simpleHeaderOverrideStyles={simpleHeaderSx(isMobile)}
            sx={meadowContainerSx(isMobile)}
        >
            {!isLoading ? (
                <Box sx={innerContainerSx(isMobile)}>
                    <Box sx={planInformationSx}>
                        <Box sx={planInformationInnerSx(isMobile)}>
                            <Typography sx={rowTextSx}>
                                Your {paymentPlan ? paymentPlan.academicTermDescription : ""}{" "}
                                Balance
                            </Typography>
                            <Typography sx={totalSx}>
                                {paymentPlan && formatter.format(paymentPlan.totalAmount)}
                            </Typography>
                            <Box sx={disclaimerSx}>
                                <Typography variant="body2" sx={rowTextSx}>
                                    This plan will automatically adjust to reflect any changes in
                                    your balance.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    {config?.isTilaEnabled && (
                        <Card sx={tilaCardSx}>
                            <Box sx={tilaCardInnerSx}>
                                {paymentPlan?.tila.map((tila: Tila, index: number) => {
                                    return (
                                        <Box key={index} sx={tilaRowSx}>
                                            <Box sx={tilaNameSx}>
                                                <Typography sx={tilaRowTextBoldSx}>
                                                    {tila.name}
                                                </Typography>
                                                <MeadowTooltip title={tila.tooltipText}>
                                                    <></>
                                                </MeadowTooltip>
                                            </Box>
                                            <Typography sx={tilaRowTextSx}>
                                                {index === 0
                                                    ? `${((tila.value ?? 0) / 100).toFixed(2)}%`
                                                    : formatter.format(tila.value)}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Card>
                    )}

                    <Card sx={paymentScheduleCardSx}>
                        <Box sx={paymentScheduleCardInnerSx}>
                            <Box sx={paymentScheduleHeaderRowSx}>
                                <Typography sx={tilaRowTextBoldSx}>Payment Schedule</Typography>
                            </Box>
                            {paymentPlan?.fee && (
                                <Box
                                    data-testid="payment-plan-fee"
                                    sx={paymentScheduleRowWrapperSx}
                                >
                                    <Box sx={paymentScheduleRow}>
                                        <Box sx={rowWithTooltipWrapperSx}>
                                            <Box sx={rowWithtooltipSx}>
                                                <Typography sx={tilaRowTextBoldSx}>
                                                    One-time start fee
                                                </Typography>
                                                <MeadowTooltip title={paymentPlanFeeTooltipText}>
                                                    <></>
                                                </MeadowTooltip>
                                            </Box>
                                            <span style={dueDateTextSx}>Due today</span>
                                        </Box>

                                        <Typography sx={tilaRowTextSx}>
                                            {formatter.format(paymentPlan.fee)}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            {paymentPlan?.installments.map(
                                (installment: PaymentPlanInstallment, index: number) => {
                                    return (
                                        <Box key={index} sx={paymentScheduleRowWrapperSx}>
                                            <Box key={index} sx={paymentScheduleRow}>
                                                <Typography sx={tilaRowTextBoldSx}>
                                                    {determineOrdinal(index + 1)} payment
                                                </Typography>
                                                <Typography sx={tilaRowTextSx}>
                                                    {formatter.format(installment.totalAmount)}
                                                </Typography>
                                            </Box>

                                            <span style={dueDateTextSx}>
                                                {formatChargeDate(installment.dueDate.toString(), {
                                                    dateOnly: true,
                                                    useShortMonthFormat: true,
                                                })}
                                            </span>
                                        </Box>
                                    );
                                }
                            )}
                        </Box>
                    </Card>

                    <Box sx={buttonContainerSx}>
                        <Box sx={checkboxWrapper(isMobile)}>
                            <CheckboxRow
                                sx={{ marginBottom: isMobile ? "0.75rem" : 0 }}
                                isChecked={termsCheck}
                                onCheck={() => {
                                    setTermsCheck(!termsCheck);
                                }}
                                termsOnly={true}
                                error={isCheckedError}
                                label={
                                    config?.isAutopayEnabled
                                        ? labelStringAutopayOn
                                        : labelStringAutopayOff
                                }
                            />
                        </Box>

                        {/* on desktop, we want to flip the order of the buttons */}
                        {isMobile ? (
                            <Box sx={buttonSectionSx(isMobile)}>
                                <Button
                                    sx={buttonSx(isMobile)}
                                    onClick={handleContinue}
                                    variant="primary"
                                >
                                    Continue
                                </Button>
                                <Button
                                    sx={buttonSx(isMobile)}
                                    onClick={handleCancel}
                                    variant="secondary"
                                >
                                    Cancel
                                </Button>
                            </Box>
                        ) : (
                            <Box sx={buttonSectionSx(isMobile)}>
                                <Button
                                    sx={buttonSx(isMobile)}
                                    onClick={handleCancel}
                                    variant="secondary"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    sx={buttonSx(isMobile)}
                                    onClick={handleContinue}
                                    variant="primary"
                                >
                                    Continue
                                </Button>
                            </Box>
                        )}
                    </Box>

                    <Box sx={disclaimerSectionSx(isMobile)}>
                        <Typography sx={{ color: GREY_500, fontSize: "0.875rem", fontWeight: 400 }}>
                            {config?.tilaDisclosureText}
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <LoadingDialog open={isLoading} />
            )}

            {isMobile ? <></> : <PoweredByMeadow />}
        </MeadowContainer>
    );
}
