import { meadowHttpFactory, IMeadowHttpFactory } from "./meadowHttpFactory";

export type IAdminService = {
    addAdminAlly(schoolId: string, studentId: string): Promise<any>;
};

export class AdminService implements IAdminService {
    private http: IMeadowHttpFactory;
    public constructor(http?: IMeadowHttpFactory) {
        this.http = http || meadowHttpFactory({});
    }

    // adds a admin ally
    public async addAdminAlly(schoolStudentId: string, schoolId: string): Promise<any> {
        const result = await this.http.post("/v1/auth/admin_ally", {
            schoolStudentId,
            schoolId,
        });

        return result.data;
    }
}
