import * as Sentry from "@sentry/react";
import MeadowContainer from "../container";
import { BLACK_000, GREY_500, GREY_800 } from "../../theme/colors";
import { Button, Card, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import useBreakpoint from "../../hooks/useBreakpoint";
import { FormEvent, useContext, useEffect, useState } from "react";
import { PaymentContext } from "../../providers/PaymentsProvider";
import CheckboxRow from "../form/CheckboxRow";
import SecurePayment from "./SecurePayment";
import CancelDialog from "./CancelDialog";
import { PaymentService } from "../../services/PaymentService";
import {
    ComputePaymentDTO,
    ComputePaymentSummaryResponse,
    PostPaymentDTO,
} from "../../services/Payments.model";
import errorInfo from "../../assets/error_info.svg";
import LoadingDialog from "../modal/LoadingDialog";
import { PaymentInstrumentType, PaymentMode } from "../../reducers/PaymentsReducer";
import { v4 as uuidv4 } from "uuid";
import { formatter } from "../billing/utilities";
import { MeadowTooltip } from "../icons/MeadowTooltip";
import { GetSchoolResponse } from "../../services/School.model";
import useAmountToPay from "../../hooks/useAmountToPay";
import {
    rowSx,
    toolTipWrapperSx,
    rowSubTitleSx,
    bodySx,
    rowWrapperSx,
    rowTitleSx,
    editButtonSx,
    errorLabelSX,
    errorLabelWrapperSX,
    footerSx,
    boldRowSubTitleSx,
    rowSubTitleTooltipSx,
} from "./PaymentSummary/styles";
import { AutopayToken } from "./payment-methods/AutopayToken";

export default function PaymentSummary() {
    const {
        getCurrentStudent,
        getPaymentInstrument,
        getAmountToPay,
        setTotalPaid,
        getStudentSchool,
        getFinixFraudToken,
        getPaymentPlanEnrollment,
    } = useContext(PaymentContext);
    const navigate = useNavigate();
    const { isMobile } = useBreakpoint();
    const currentStudent = getCurrentStudent();
    const [finixFraudToken, setFinixFraudToken] = useState<string | null>(null);
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [authorizeCheck, setAuthorizeCheck] = useState<boolean>(false);
    const [authorizeCheckError, setAuthorizeCheckError] = useState<boolean>(false);
    const [summaryData, setSummaryData] = useState<ComputePaymentSummaryResponse | null>(null);
    const [school, setSchool] = useState<GetSchoolResponse | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const paymentInstrument = getPaymentInstrument();
    const amountToPay = getAmountToPay();
    const paymentPlanEnrollment = getPaymentPlanEnrollment();
    const innerContainerSx = {
        width: isMobile ? "100%" : "24.5rem",
    };
    useAmountToPay("payment-summary");

    useEffect(() => {
        if (currentStudent?.studentId && summaryData?.total) {
            setTotalPaid(summaryData.total, currentStudent.studentId);
        }
    }, [summaryData]);

    useEffect(() => {
        // if there is no current student, we should navigate to the signin page
        if (!currentStudent) {
            navigate("/signin");
            return;
        }
        // if for some reason the payment instrument is not set, we should navigate to the billing page
        if (!paymentInstrument || !paymentInstrument?.paymentInstrumentId) {
            navigate("/billing");
            return;
        }
        // if we are in a payment plan, the amountToPay is potentially 0
        // if we are not in a payment plan, the amountToPay should be set
        if (
            paymentPlanEnrollment?.paymentPlanId === undefined &&
            (!amountToPay || amountToPay <= 0)
        ) {
            navigate("/billing");
            return;
        }

        const studentSchool = getStudentSchool();
        const finixToken = getFinixFraudToken();

        setFinixFraudToken(finixToken);
        setSchool(studentSchool);

        const paymentSummaryBody: ComputePaymentDTO = {
            paymentForStudentId: currentStudent!.studentId,
            // it is safe to assume that the amount to pay is set
            paymentAmount: amountToPay!,
            paymentCurrency: "USD",
            paymentInstrumentId: paymentInstrument!.paymentInstrumentId,
            paymentPlanToActivateId: paymentPlanEnrollment?.paymentPlanId,
        };
        async function fetchPaymentSummary() {
            setIsProcessing(true);
            try {
                const paymentService = new PaymentService();
                const paymentSummaryResponse = await paymentService.getSummary(paymentSummaryBody);
                setSummaryData(paymentSummaryResponse);
            } catch (error: any) {
                setErrorMessage(error.response.data.error);
                Sentry.captureException(error);
            } finally {
                setIsProcessing(false);
            }
        }
        fetchPaymentSummary();
    }, []);

    const labelString = () => <>Authorize payment</>;
    const determineDisplayText = (paymentMode: PaymentMode) => {
        switch (paymentMode) {
            case "paymentPlanFeePlusFirstPayment":
                return "First payment";
            case "pastDuePlusNextPayment":
                return "Past due + Next payment";
            case "pastDue":
                return "Past due";
            case "partial":
                return "Other amount";
            case "paymentDue":
                return "Payment due";
            default:
                return "Payment due";
        }
    };
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsProcessing(true);

        // if the user is not logged in, we should navigate to the sign in page
        if (!currentStudent) {
            navigate("/signin");
            setIsProcessing(false); // Reset processing state on early return
            return;
        }
        // the authorize check is only for bank accounts
        if (paymentInstrument?.type === PaymentInstrumentType.BANK_ACCOUNT && !authorizeCheck) {
            setAuthorizeCheckError(true);
            setIsProcessing(false); // Reset processing state on early return
            return;
        }
        // if for some reason the payment instrument is not set, we should navigate to the billing page
        if (!paymentInstrument || !paymentInstrument?.paymentInstrumentId) {
            setIsProcessing(false);
            navigate("/billing");
            return;
        }
        if (!summaryData) {
            setIsProcessing(false);
            return;
        }
        if (!paymentInstrument) {
            setIsProcessing(false);
            navigate("/billing");
            return;
        }
        try {
            const paymentService = new PaymentService();
            const transferBody: PostPaymentDTO = {
                paymentForStudentId: currentStudent.studentId,
                paymentAmount: amountToPay!,
                paymentCurrency: "USD",
                paymentInstrumentId: paymentInstrument?.paymentInstrumentId,
                idempotencyId: uuidv4(),
                paymentFees: summaryData.fees,
                paymentTotal: summaryData.total,
                fraudSessionId: finixFraudToken ? finixFraudToken : "",
                paymentPlanToActivateId: paymentPlanEnrollment?.paymentPlanId,
            };
            await paymentService.createTransfer(transferBody);

            navigate("/payment/success");
        } catch (error: any) {
            setErrorMessage(error.response.data.error);
            Sentry.captureException(error);
            setIsProcessing(false);
        }
    };

    const formatCardString = () => {
        if (paymentInstrument?.type === PaymentInstrumentType.PAYMENT_CARD) {
            // If card brand and card type are both unknown, our backend returns empty string for both, this covers that case
            if (!paymentInstrument?.cardType && !paymentInstrument?.cardBrand) {
                return `Card ending in •••• ${paymentInstrument?.cardLastFour}`;
            }
            if (!paymentInstrument?.cardType) {
                // This covers unknown card types, which are returned as the empty string by the backend
                return `${paymentInstrument?.cardBrand} card ending in •••• ${paymentInstrument?.cardLastFour}`;
            } else {
                return `${paymentInstrument?.cardBrand} ${paymentInstrument?.cardType} card ending in •••• ${paymentInstrument?.cardLastFour}`;
            }
        }
    };
    function renderFees() {
        if (summaryData?.fees && summaryData.fees.length > 0) {
            return summaryData.fees.map((fee, index) => (
                <Box key={index} sx={rowSx}>
                    <Box sx={toolTipWrapperSx}>
                        <Typography sx={rowSubTitleTooltipSx}>{fee.displayName}</Typography>
                        {fee.tooltipText && (
                            <MeadowTooltip title={fee.tooltipText} sx={{ marginLeft: "1rem" }}>
                                <></>
                            </MeadowTooltip>
                        )}
                    </Box>
                    <Typography sx={rowSubTitleSx}>{formatter.format(fee.amount)}</Typography>
                </Box>
            ));
        }
        return <></>;
    }
    function BankAccount() {
        return (
            <>
                <Box sx={innerContainerSx}>
                    <Card sx={bodySx}>
                        <Box sx={rowWrapperSx}>
                            <Box sx={rowSx}>
                                <Typography sx={rowTitleSx}>Amount</Typography>
                                <Button
                                    sx={editButtonSx}
                                    onClick={() => navigate("/payment/amount?edit=true")}
                                >
                                    Edit
                                </Button>
                            </Box>
                            {amountToPay! > 0 && (
                                <Box sx={rowSx}>
                                    <Typography sx={rowSubTitleSx}>
                                        {currentStudent?.paymentMode &&
                                            determineDisplayText(currentStudent?.paymentMode)}
                                    </Typography>

                                    <Typography sx={rowSubTitleSx}>
                                        {summaryData?.amount &&
                                            formatter.format(summaryData?.amount)}
                                    </Typography>
                                </Box>
                            )}
                            {renderFees()}
                            <Box sx={rowSx}>
                                <Typography sx={{ ...boldRowSubTitleSx, color: BLACK_000 }}>
                                    Total
                                </Typography>

                                <Typography sx={{ ...boldRowSubTitleSx, color: BLACK_000 }}>
                                    {summaryData?.total && formatter.format(summaryData?.total)}
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Card sx={bodySx}>
                        <Box sx={rowWrapperSx}>
                            <Box sx={rowSx}>
                                <Typography sx={rowTitleSx}>Payment method</Typography>
                                <Button
                                    sx={editButtonSx}
                                    onClick={() => navigate("/payment/method")}
                                >
                                    Edit
                                </Button>
                            </Box>

                            <Box sx={rowSx}>
                                <Typography sx={rowSubTitleSx}>
                                    {paymentInstrument?.type ===
                                        PaymentInstrumentType.BANK_ACCOUNT &&
                                        `${paymentInstrument?.bankAccountType} account ending in •••• ${paymentInstrument?.bankAccountNumberLastFour}`}
                                </Typography>
                            </Box>
                            {paymentPlanEnrollment && paymentPlanEnrollment.isAutopayEnabled && (
                                <AutopayToken />
                            )}
                            {errorMessage && errorMessage.length > 0 && (
                                <Box sx={errorLabelWrapperSX}>
                                    <img src={errorInfo} />
                                    <Typography sx={errorLabelSX}>{errorMessage}</Typography>
                                </Box>
                            )}
                        </Box>
                    </Card>
                    <Card sx={bodySx}>
                        <Box sx={rowWrapperSx}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                <Typography
                                    sx={{
                                        color: GREY_500,
                                        fontSize: "0.875rem",
                                        lineHeight: "1rem",
                                        fontWeight: 400,
                                    }}
                                >
                                    To:{" "}
                                </Typography>
                                <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                                    {school?.name}
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                                <Typography
                                    sx={{
                                        color: GREY_500,
                                        fontSize: "0.875rem",
                                        lineHeight: "1rem",
                                        fontWeight: 400,
                                    }}
                                >
                                    Student Account #:{" "}
                                </Typography>
                                <Typography sx={{ fontWeight: 700, fontSize: "0.875rem" }}>
                                    {currentStudent?.accountNumber}
                                </Typography>
                            </Box>
                        </Box>
                    </Card>

                    <Card sx={bodySx}>
                        <Typography
                            sx={{
                                color: GREY_500,
                                fontWeight: 400,
                                fontSize: "0.875rem",
                                lineHeight: "1.25rem",
                            }}
                        >
                            By checking the “Authorize payment” checkbox, I authorize Meadow Pay, on
                            behalf of <span style={{ fontWeight: 500 }}>{school?.name}</span>, to
                            debit the bank account specified above. I attest that this transaction
                            is lawful and complies with U.S. Law.
                        </Typography>
                    </Card>

                    <Card elevation={1} sx={bodySx}>
                        <CheckboxRow
                            isChecked={authorizeCheck}
                            onCheck={() => setAuthorizeCheck(!authorizeCheck)}
                            label={labelString}
                            noLabelSpacing
                            error={authorizeCheckError}
                        />
                    </Card>
                    <Box
                        sx={{
                            padding: "0 1.5rem",
                            paddingBottom: "1rem",
                        }}
                    >
                        <Button
                            sx={{
                                marginTop: "1.5rem",
                            }}
                            disabled={isProcessing}
                            onClick={(e: FormEvent<any>) => handleSubmit(e)}
                            variant="primary"
                            type="submit"
                        >
                            Send payment
                        </Button>
                        <Button
                            sx={{
                                marginTop: "0.75rem",
                            }}
                            onClick={() => setIsCancel(true)}
                            variant="secondary"
                        >
                            Cancel
                        </Button>
                        <SecurePayment />
                    </Box>
                    <Box sx={footerSx(isMobile)}>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "0.875rem",
                            }}
                        >
                            Once you send the payment, it will be processed on the date indicated.
                            Should you decide to cancel the payment after sending, you are
                            responsible for contacting your bank to request a “stop payment”. Please
                            be aware that the stop payment must reach your bank prior to your
                            account having been debited. If any payment is returned by the issuing
                            bank for any reason, a ${school?.achReturnFee ?? ""} non-refundable fee
                            will be charged. Multiple returned payments may result in the inability
                            to use bank accounts as the payment method. You can avoid returns by
                            providing a valid bank account number and ensuring that there are enough
                            funds in the account to cover the payment.
                        </Typography>
                    </Box>
                </Box>
            </>
        );
    }
    function CreditCard() {
        return (
            <>
                <Box sx={innerContainerSx}>
                    <Card sx={bodySx}>
                        <Box sx={rowWrapperSx}>
                            <Box sx={rowSx}>
                                <Typography sx={rowTitleSx}>Amount</Typography>
                                <Button
                                    sx={editButtonSx}
                                    onClick={() => navigate("/payment/amount?edit=true")}
                                >
                                    Edit
                                </Button>
                            </Box>

                            {amountToPay! > 0 && (
                                <Box sx={rowSx}>
                                    <Typography sx={rowSubTitleSx}>
                                        {currentStudent?.paymentMode &&
                                            determineDisplayText(currentStudent?.paymentMode)}
                                    </Typography>

                                    <Typography sx={rowSubTitleSx}>
                                        {summaryData?.amount &&
                                            formatter.format(summaryData?.amount)}
                                    </Typography>
                                </Box>
                            )}
                            {renderFees()}
                            <Box sx={rowSx}>
                                <Typography sx={{ ...boldRowSubTitleSx, color: BLACK_000 }}>
                                    Total
                                </Typography>

                                <Typography sx={{ ...boldRowSubTitleSx, color: BLACK_000 }}>
                                    {summaryData?.total && formatter.format(summaryData?.total)}
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Card sx={bodySx}>
                        <Box sx={rowWrapperSx}>
                            <Box sx={rowSx}>
                                <Typography sx={rowTitleSx}>Payment method</Typography>
                                <Button
                                    sx={editButtonSx}
                                    onClick={() => navigate("/payment/method")}
                                >
                                    Edit
                                </Button>
                            </Box>

                            <Box sx={rowSx}>
                                <Typography sx={rowSubTitleSx}>{formatCardString()}</Typography>
                            </Box>
                            {paymentPlanEnrollment && paymentPlanEnrollment.isAutopayEnabled && (
                                <AutopayToken />
                            )}
                            {errorMessage && errorMessage.length > 0 && (
                                <Box sx={errorLabelWrapperSX}>
                                    <img src={errorInfo} />
                                    <Typography sx={errorLabelSX}>{errorMessage}</Typography>
                                </Box>
                            )}
                        </Box>
                    </Card>
                    <Card sx={bodySx}>
                        <Box sx={rowWrapperSx}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                <Typography
                                    sx={{
                                        color: GREY_500,
                                        fontSize: "0.875rem",
                                        lineHeight: "1rem",
                                        fontWeight: 400,
                                    }}
                                >
                                    To:{" "}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: "0.875rem",
                                        lineHeight: "1rem",
                                    }}
                                >
                                    {school?.name}
                                </Typography>
                            </Box>

                            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                <Typography
                                    sx={{
                                        color: GREY_500,
                                        fontSize: "0.875rem",
                                        lineHeight: "1rem",
                                        fontWeight: 400,
                                    }}
                                >
                                    Student Account #:{" "}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: "0.875rem",
                                        lineHeight: "1rem",
                                    }}
                                >
                                    {currentStudent?.accountNumber}
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                    <Box
                        sx={{
                            padding: "0 1.5rem",
                            paddingBottom: "1rem",
                        }}
                    >
                        <Button
                            sx={{
                                marginTop: "1.5rem",
                            }}
                            disabled={isProcessing}
                            onClick={(e: FormEvent<any>) => handleSubmit(e)}
                            variant="primary"
                            type="submit"
                        >
                            Send payment
                        </Button>
                        <Button
                            sx={{
                                marginTop: "0.75rem",
                            }}
                            onClick={() => setIsCancel(true)}
                            variant="secondary"
                        >
                            Cancel
                        </Button>
                        <SecurePayment />
                    </Box>
                </Box>
            </>
        );
    }
    return (
        <MeadowContainer
            fullWidth
            noPadding
            showSimpleHeader={true}
            simpleHeaderText="Summary"
            sx={{
                backgroundColor: GREY_800,
                alignItems: "center",
            }}
        >
            <Box sx={{ width: isMobile ? "100%" : "auto" }}>
                {paymentInstrument?.type === PaymentInstrumentType.BANK_ACCOUNT ? (
                    <BankAccount />
                ) : (
                    <CreditCard />
                )}
            </Box>
            <LoadingDialog open={isProcessing} />
            <>{isCancel && <CancelDialog open={isCancel} onClose={() => setIsCancel(false)} />}</>
        </MeadowContainer>
    );
}
