import clock from "../../../assets/clock.svg";
import clockRed from "../../../assets/clock_red.svg";
import checkmarkOutline from "../../../assets/checkmark_outline.svg";
import { InstallmentStatus, PaymentPlanInstallment, StudentPaymentPlan } from "../Student.model";
import { capitalizeFirstLetter, formatter } from "../utilities.js";
import { GREY_500, RED_700 } from "../../../theme/colors.js";
import { Typography } from "@mui/material";

export const determineInstallmentIcon = (installmentStatus: InstallmentStatus) => {
    if (installmentStatus === "Upcoming") {
        return <img src={clock} />;
    }

    if (installmentStatus === "Past Due") {
        return <img src={clockRed} />;
    }

    if (installmentStatus === "Due Today") {
        return <img src={clockRed} />;
    }

    if (installmentStatus === "Paid") {
        return <img src={checkmarkOutline} />;
    }
};

export const determineInstallmentStatement = (paymentPlan: StudentPaymentPlan) => {
    if (paymentPlan.installmentsRemaining === 1) {
        return `You have ${paymentPlan.installmentsRemaining} payment remaining for
        ${capitalizeFirstLetter(paymentPlan.periodOfEnrollment)}`;
    }

    if (paymentPlan.installmentsRemaining > 1) {
        return `You have ${paymentPlan.installmentsRemaining} payments remaining for
        ${capitalizeFirstLetter(paymentPlan.periodOfEnrollment)}`;
    }

    return `Payment due for ${paymentPlan.periodOfEnrollment}.`;
};

const installmentStatusMap = {
    Upcoming: GREY_500,
    "Past Due": RED_700,
    "Due Today": RED_700,
    Paid: GREY_500,
};

export const getInstallmentStatusElement = (installmentStatus: InstallmentStatus): JSX.Element => {
    return (
        <Typography
            sx={{
                fontSize: "0.875rem",
                fontWeight: 400,
                lineHeight: "1rem",
                color: installmentStatusMap[installmentStatus],
            }}
        >
            {installmentStatus}
        </Typography>
    );
};

export const getInstallmentAmountElement = (installment: PaymentPlanInstallment): JSX.Element => {
    return (
        <Typography
            sx={{
                fontSize: "0.875rem",
                fontWeight: 400,
                lineHeight: "1rem",
                color: installmentStatusMap[installment.status],
            }}
        >
            {formatter.format(installment.totalAmount)}
        </Typography>
    );
};
